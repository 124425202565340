import ButtonPrimary from "./ButtonPrimary"
import "./CTASection.css"
import "../sections/HomeDescSection.css"

export default function CTASection ({heading, text, url}) {
    return(
        <div className="cta-section">
            <h2>{heading}</h2>
            <ButtonPrimary text={text} url={url}/>
        </div>
        )
}