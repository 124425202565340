import React from 'react'
import "./ProjectOverviewSection.css";

export default function ProjectOverviewSection({title, logo, desc, preview}) {
  return (
    <div className='project-overview-section'>
      <div className='project-overview-container'>
        <img className='project-logo' src={logo} alt={logo} />
        <h2>{title}</h2>
        <p>{desc}</p>
     </div>
     <img className='project-preview' src={preview} alt={preview} />
    </div>
  )
}
