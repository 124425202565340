import React from 'react'
import "./OpenCard.css";

export default function OpenCard({img, name, items}) {
  return (
    <div className='open-card'>
      <img src={img} alt={img} />
      <h2 className="open-card-name">{name}</h2>
      <ul>
        {items.map((item, index) =>
          <li key={index}>
            {item}
          </li>
        )}
      </ul>
    </div>
  )
}