import ButtonPrimary from "../components/ButtonPrimary";
import ButtonSecondary from "../components/ButtonSecondary";
import "./HomeDescSection.css";

export default function HomeDesc (){
    return(
        <section className="home-desc-section">
             <div className="home-desc-container">
                <h1>Hi, I'm Luka</h1>
                <p>I'm a media designer from Munich, Germany with multidisciplinary expertise in several aspects of design, specialising in UI/UX Design and Webflow Development. Whether you need a standalone solution or a combination of several cross-medial touchpoints into a coherent visual presence, you've come to the right place. </p>
                 <div className="home-desc-cta-container">
                    <ButtonPrimary text="View Portfolio" url="/ui-ux-design"/>
                    <ButtonSecondary text="About me" url="/about"/>
                </div>  
            </div>
        </section>
    )
}