import "./Footer.css"
import talenticLogo from "../assets/talentic-logo.png"
import { Link, NavLink } from 'react-router-dom';
import footerLogo from "../assets/headlines/luka-zec-logo.png"

export default function Footer (){
    return(
        <footer>
            <div className="footer-container">
                <div className="footer-desc">
                    <img src={footerLogo} alt="Luka Zec Media Design" className="footer-logo" />
                    <p>Developing quality digital experiences across all aspects of design, from your brand identity to modern user interfaces.</p>
                </div>
                <nav className="footer-nav">               
                    <ul>
                        <p>Services</p>
                        <Link to="/ui-ux-design" className="footer-link">UI/UX Design</Link>
                        <Link to="/web-development" className="footer-link">Web Development</Link>
                        <Link to="/graphic-design" className="footer-link">Graphic Design</Link>
                        <Link to="/brand-identity" className="footer-link">Brand Identity</Link>
                    </ul>

                    <ul>
                        <p>Sitemap</p>
                        <Link to="/ui-ux-design" className="footer-link">Portfolio</Link>
                        <Link to="/about" className="footer-link">About</Link>
                        <Link to="/contact" className="footer-link">Contact</Link>
                        <Link to="/impressum" className="footer-link">Impressum</Link>
                    </ul>
                </nav>
                </div>
            </footer> 
    )
}
