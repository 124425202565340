import React from 'react';
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./WebDevelopmentProjects.css";
import TalenticLogo from "../../../assets/talentic-logo.png";
import ProjectTalenticWebsitePreview from "../../../assets/project-previews/project-talentic-website-preview.png";
import ProjectOutline from '../../../components/ProjectOutline';
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import TalenticInformationArchitecture from "../../../assets/project-assets/talentic-assets/talentic-information-architecture.png";
import TalenticUserFlow from "../../../assets/project-assets/talentic-assets/talentic-user-flow.png";
import TalenticWireframes from "../../../assets/project-assets/talentic-assets/talentic-wireframes.png";
import TalenticPrototyping from "../../../assets/project-assets/talentic-assets/talentic-prototyping.jpg";
import TalenticCopywriting from "../../../assets/project-assets/talentic-assets/talentic-copywriting.png";
import TalenticDesignGuide from "../../../assets/project-assets/talentic-assets/talentic-style-guide.svg";
import TalenticPrototype1 from "../../../assets/project-assets/talentic-assets/talentic-prototype-1.png";
import TalenticPrototype2 from "../../../assets/project-assets/talentic-assets/talentic-prototype-2.png";
import TalenticPrototype3 from "../../../assets/project-assets/talentic-assets/talentic-prototype-3.png";
import TalenticMockupMobile from "../../../assets/project-assets/talentic-assets/talentic-mockup-mobile.jpg";
import WebflowImage from "../../../assets/project-assets/talentic-assets/webflow-image.png";
import TalenticLogoRedesign from "../../../assets/project-assets/talentic-assets/talentic-logo-redesign.png";

import CognitiveWalkthroughIcon from "../../../assets/icons/cognitive-walkthrough-icon.png";
import HeuristicEvaluationIcon from "../../../assets/icons/heuristic-evaluation-icon.png";
import AccessibilityIconColor from "../../../assets/icons/accessibility-icon-color.png";


export default function WebDevelopmentProject2() {
  return (
    <div className='web-development-project'>
      <h1>Web Development Project</h1>
      <ProjectOverviewSection
        logo={TalenticLogo}
        desc="Talentic GmbH is a specialized personnel service for recruiting on demand, HR management and talent marketing from Munich, Germany. This project included a complete redesign and development of their website based on their newly forming corporate identity. Their primary business goal was to communicate their products and services to both B2B and B2C customers, as well as job-seeking candidates."
        preview={ProjectTalenticWebsitePreview}
      />
      <ProjectOutline
        color="#187cf7"
        title="Case study: Talentic website"
        numbers={["01", "02", "03", "04", "05", "06"]}
        chapters={["Brand Revitalization", "User experience", "User Interface Design", "Mobile responsiveness", "Usability review", "Webflow Development"]}
      />

      <section id="brand-revitalization">
        <div className="project-section-container">
        <h2><span className="talentic-color">01 </span>Brand Revitalization</h2>
          <div className="project-segment">
            <p>At the beginning of our project, Talentic was a start-up and had no concrete corporate identity. Their website was therefore lacking in all aspects of visual communication and needed a rework of its visual language in accordance to the newly developing identity.</p>
            <img src={TalenticLogoRedesign} className="project-image" alt='Talentic logo redesign' />
          </div>
        </div>
      </section>

      <section id='user-experience'>
        <div className="project-section-container">
          <h2><span className="talentic-color">02 </span>User Experience</h2>
          <div className="project-segment">
            <h3>Briefing, research & analysis</h3>
            <p>The first phase of the project was based on understanding the business needs & goals of Talentic. Due to the nature and variety of Talentic's services, it was crucial to effectively communicate what we are about and what we offer.</p>
            <p>The challenge was in mediating between two very different target audiences: HR professionals and candidates while catering to both of their needs & goals. More about this in our <a>UI/UX Design service.</a></p>
          </div>
          <div className="project-segment">
            <h3>Information architecture</h3>
            <p>The solution to the app's information structure could be found in a relatively simple architecture, with the main navigation linking to key subpages and branching off into two service categories which contain specific services.</p>
            <img src={TalenticInformationArchitecture} className="project-image" alt="Talentic information architecture" />
          </div>
          <div className="project-segment">
            <h3>User flow diagrams</h3>
            <p>Our end goal of maximizing conversions was achieved by continually reminding the user about the possibility of personal contact, referencing our services and letting them know where to find crucial information about them.</p>
            <img src={TalenticUserFlow} className="project-image" alt="Talentic user flow" />
          </div>
          <div className="project-segment">
            <h3>Wireframing & prototyping</h3>
            <p>The wireframing process includes developing simple, low-fidelity sketches of the overall page layout and information structure - in constant communication with the client. This ensures full understanding and precision about the general idea, goals & desires before moving on to more resource-intensive stages of development. </p>
            <p>Prototyping takes this agreed upon base to the next level, by designing an interactive click-dummy for the client to test. Only after the wireframes and prototypes have been meticulously tested, evaluated and optimized does the final design go into development. This prevents unnecessary wasting of time and resources in later stages where changes are harder to make.</p>
            <img src={TalenticWireframes} className="project-image" alt="Talentic website wireframes" />
            <img src={TalenticPrototyping} className="project-image" alt="Talentic website prototyping" />
          </div>
          <div className="project-segment">
            <h3>UX copywriting</h3>
            <p>Talentic's visual web presence had to be supplemented with persuasive and compelling written content that engages the target audience, communicates messages effectively, and motivates readers to discover their services and contact Talentic.</p>
            <img src={TalenticCopywriting} className="project-image" alt="Talentic copywriting" />
          </div>
          <div className="project-segment">
            <h3>Usability & Accessibility</h3>
            <div className="segment-columns">
              <p>To make Talentic's website an enjoyable user experience that is accessible to all users regardless of visual and motor disabilities, I have laid great importance in adhering to official accessibility & usability standards.<br /><br />Most notably, Google Material Design guidelines, W3C Web Content Accessibility Guidelines (WCAG) & various Usability Heuristics ensure that the website remains robust, perceivable, operable and understandable to all.</p>
              <img className="segment-columns-icon" src={AccessibilityIconColor} alt="Accesibility icon" />
            </div>
          </div>
        </div>
      </section>

      <section id='user-interface'>
        <div className="project-section-container">
          <h2><span className="talentic-color">03 </span>User Interface</h2>
          <div className="project-segment">
            <h3>Design guide</h3>
            <p>To ensure consistent development and maintenance of the digital user interface, I've made a comprehensive design guide for Talentic's website. This included things like the color palette, typographic stylings as well as an icon set adapted to Talentic's CI.</p>
            <img src={TalenticDesignGuide} className="project-image" alt="Talentic design guide" />
          </div>
          <div className="project-segment">
            <h3>High-fidelity UI prototype</h3>
            <p>Here's a few screenshots of the final UI prototype that resulted from all the previous steps and considerations. This is the 1:1 design of the website that then goes into development.</p>
            <img src={TalenticPrototype1} className="project-image" alt="Talentic website prototype 1" />
            <img src={TalenticPrototype1} className="project-image" alt="Talentic website prototype 1" />
            <img src={TalenticPrototype1} className="project-image" alt="Talentic website prototype 1" />
          </div>
        </div>
      </section>

      <section id='mobile-responsiveness'>
        <div className="project-section-container">
          <h2><span className="talentic-color">04 </span>Mobile responsiveness</h2>
          <div className="project-segment">
            <p>To ensure the website's responsiveness across all systems & devices, I've dedicated special attention to designing the mobile version of the website.</p>
            <img src={TalenticMockupMobile} className="project-image" alt="Talentic website mobile mockup" />
          </div>
        </div>
      </section>

      <section id='usability-review'>
        <div className="project-section-container">
          <h2><span className="talentic-color">05 </span>Usability review</h2>
          <div className="project-segment">
            <h3>Cognitive walkthrough</h3>
            <div className="segment-columns">
              <p>This task-based usability-inspection method allowed me to walk through specific scenarios with the traits, goals and needs of specific user personas in mind. This allows me to test functionality and user experience while aiming to accomplish specific tasks.</p>
              <img className="segment-columns-icon" src={CognitiveWalkthroughIcon} alt="Cognitive walktgrough icon" />
            </div>
            <h3>Heuristic evaluation</h3>
            <div className="segment-columns">
              <p>Evaluating Talentic's website against a set of prominent usability heuristics helps me ensure that your digital product stays usable, operable and robust in all use-case scenarios.</p>
              <img className="segment-columns-icon" src={HeuristicEvaluationIcon} alt="Heuristic evaluation" />
            </div>
          </div>
        </div>
      </section>

      <section id="webflow-development">
        <div className="project-section-container">
          <h2><span className="talentic-color">06 </span> Webflow Development</h2>
          <div className="project-segment">
            <p>The final design was ultimately developed into a responsive website using Webflow. This step also included extra support services such as ongoing site maintenance and basic Search Engine Optimization (SEO).</p>
            <img src={WebflowImage} className="project-image" alt="Webflow logo image" />
          </div>
        </div>
      </section>

      <CTASection heading="Interested in working together?" text="Contact me" url="/contact" />
      <Footer/>
    </div>
  );
}
