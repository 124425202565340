import React from 'react'
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./UIUXDesignProjects.css";
import TalenticLogo from "../../../assets/talentic-logo.png";
import TinkersforgeProjectPreview from "../../../assets/project-previews/tinkersforge-project-preview.png";
import Footer from '../../../components/Footer';
import CTASection from '../../../components/CTASection';
import ProjectOutline from '../../../components/ProjectOutline';

import TinkersforgeWireframes from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-wireframes.png";
import TinkersforgePrototype from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-prototype.png";
import TinkersforgePrototyping from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-prototyping.png";
import TinkersforgePersona from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-persona.png";
import TinkersforgeInformationArchitecture from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-information-architecture.png";
import TinkersforgeDesignGuide from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-design-guide.png";
import TinkersforgeUserJourneyMap from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-user-journey-map.png";
import TinkersforgeUserFlow from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-userflow.png";
import TinkersforgeCopywriting from "../../../assets/project-assets/tinkersforge-assets/tinkersforge-copywriting.png";
import HeuristicEvaluationIcon from "../../../assets/icons/heuristic-evaluation-icon.png";
import CognitiveWalkthroughIcon from "../../../assets/icons/cognitive-walkthrough-icon.png";
import AccessibilityIconColor from "../../../assets/icons/accessibility-icon-color.png";
import TinkersforgeLogo from "../../../assets/tinkersforge-logo.png";

export default function UIUXDesignProject3() {
  return (
    <div className='ui-ux-design-project' id="ui-ux-design-project-3">
    <h1>UI/UX Design Project</h1>
      <ProjectOverviewSection
        logo={TinkersforgeLogo}
        desc="Tinker's Forge is a web app that allows users to compare computer components and build custom PCs with the help of AI tools. The aim of this project was to develop an intuitive user interface for relatively complex key functions which are usually convoluted and unclear on most websites."
        preview={TinkersforgeProjectPreview}/>
      <ProjectOutline color="#9F48F6" title="Case study: Tinker's Forge web-app" numbers ={["01", "02", "03"]} chapters={["User experience", "User interface design", "Usability review & testing"]}/>
      
      <section id="user-experience">
        <div className="project-section-container">
          <h2><span className="tinkersforge-color">01</span> User Experience</h2>
          <div className="project-segment">
            <h3>Briefing, research & analysis</h3>
            <p>The first phase of the project was based on conceptualizing Mythical Shiga's basic app structure and features, as well as outlining  the overall goal of the project, with financial and business considerations in mind.</p>
          </div>
          <div className="project-segment">
            <h3>Target audience analysis</h3>
            <p>In order to perfectly tailor the app's features and find the appropriate visual language and app structure for potential users, the first step was to define and analysie the target audience. One of the methods I used was creating user personas.</p>
            <img src={TinkersforgePersona} className="project-image" alt="Tinker's Forge persona" />
          </div>
          <div className="project-segment">
            <h3>Context of use - User journey mapping</h3>
            <p>With insights into the target audience, I could then map out the optimal desired path of the given persona from their initial desire to build their dream PC, through the discovery of Tinker's Forge and it's features to their use-cases and ultimately, success and satisfaction.</p>
            <img src={TinkersforgeUserJourneyMap} className="project-image" alt="Tinker's Forge user journey map" />
          </div>
          <div className="project-segment">
            <h3>User flow diagrams</h3>
            <p>User flow diagrams allowed me to visualise the necessary steps a user is expected to take to accomplish the task of building and ultimately, buying their dream PCy. These include using tools such as the AI chatbot and component comparison feature while checking for compatibility, comparing prices and finding the optimal combination.</p>
            <img src={TinkersforgeUserFlow} className="project-image" alt="Tinker's Forge user flow" />
          </div>
          <div className="project-segment">
            <h3>Information architecture</h3>
            <p>The solution to the app's information structure could be found in prividing interactive access between the three core features that aid the user in achieving their goals, while offering external resources for viewing prices and purchasing the components.</p>
            <img src={TinkersforgeInformationArchitecture} className="project-image" alt="Tinker's Forge information architecture" />
          </div>
          <div className="project-segment">
            <h3>Wireframing</h3>
            <p>The wireframing process includes developing simple, low-fidelity sketches of the overall page layout and information structure - in constant communication with the client. This ensures full understanding and precision about the general idea, goals & desires before moving on to more resource-intensive stages of development.</p>
            <img src={TinkersforgeWireframes} className="project-image" alt="Tinker's Forge wireframes" />
          </div>
          <div className="project-segment">
            <h3>High-fidelity prototyping</h3>
            <p>Prototyping takes this agreed upon base to the next level, by designing an interactive click-dummy for the client to test. Only after the wireframes and prototypes have been meticulously tested, evaluated and optimized does the final design go into development. This prevents unnecessary wasting of time and resources in later stages where changes are harder to make.</p>
            <img src={TinkersforgePrototyping} className="project-image" alt="Tinker's Forge prototyping process" />
          </div>
          <div className="project-segment">
            <h3>UX copyriting</h3>
            <p>The app's user interface design had to be supplemented with persuasive and compelling written content that engages the target audience, communicates messages effectively, and allows users to understand key features. The complex table components used for navigating and combining components serve as a good example of carefully curated copy.</p>
            <img src={TinkersforgeCopywriting} className="project-image" alt="Tinker's Forge copywriting" />
          </div>
          <div className="project-segment">
            <h3>Usability & accessibility</h3>
            <div className="segment-columns">
                <p>To make Tinker's Forge an enjoyable user experience that is accessible to all users regardless of visual and motor disabilities, I have laid great importance in adhering to official accessibility & usability standards. Most notably, Google Material Design guidelines,W3C Web Content Accessibility Guidelines (WCAG) & various Usability Heuristics ensure that the website remains robust, perceivable, operable and understandable to all.</p>
                <img src={AccessibilityIconColor} className="segment-columns-icon" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="user-interface-design">
        <div className="project-section-container">
          <h2><span className="tinkersforge-color">02</span> User interface design</h2>
          <div className="project-segment">
            <h3>Style guide and design system</h3>
            <p>To ensure consistent development and maintenance of the digital user interface, I've made a comprehensive style guide for the Tinker's Forge web-app. This included things like the color palette and typographic stylings, as well as a comprehensive design system with reusable components.</p>
            <img src={TinkersforgeDesignGuide} className="project-image" alt="Tinker's Forge design guide" />
          </div>
          <div className="project-segment">
            <h3>High-fidelity UI prototype</h3>
            <p>Here's a few screenshots of the final UI prototype that resulted from all the previous steps and considerations. This is the 1:1 design of the app that then goes into development.</p>
            <img src={TinkersforgePrototype} className="project-image" alt="Tinker's Forge high-fidelity UI prototype" />
          </div>
        </div>
      </section>

      <section id="usability-review">
        <div className="project-section-container">
          <h2><span className="tinkersforge-color">03</span> Usability review</h2>
          <div className="project-segment">
            <p>To make sure that the Tinker's Forge web-app adheres to modern usability standards and best practices, the final stages of the design process included conducting a usability review.</p>
            <h3>Cognitive walkthrough</h3>
            <div className="segment-columns">
              <p>This task-based usability-inspection method allowed me to walk through specific scenarios with the traits, goals and needs of specific user personas in mind. This allows me to test  functionality and user experience while aiming to accomplish specific tasks.</p>
              <img className="segment-columns-icon" src={CognitiveWalkthroughIcon} alt="" />
            </div>
            <h3>Heuristic evaluation</h3>
            <div className="segment-columns">
              <p>Evaluating the Tinker's Forge web-app against a set of prominent usability heuristics helps me ensure that your digital product stays usable, operable and robust in all use-case scenarios.</p>
              <img className="segment-columns-icon" src={HeuristicEvaluationIcon} alt="" />
            </div>
          </div>

          
        </div>
      </section>
      <CTASection heading="Interested in working together?" text="Contact me" url="/contact"/>
      <Footer/>
    </div>
  )
}
