import React from 'react'
import "../Pages.css"
import headlineBrandIdentity from "../../assets/headlines/headline-brand-identity.svg"
import Footer from '../../components/Footer'
import Subnavigation from '../../components/Subnavigation'

export default function BrandIdentity() {
  return (
    <div className="page-container">
        <div className='service-section'>
            <img src={headlineBrandIdentity} alt="Brand Identity - Powered by Talentic" className='headline'></img>
            <Subnavigation />
            <div className="thumbnail-container coming-soon">
              <p>Coming soon!</p>
              <p className="smaller-text">In case you'd like to see my brand identity projects before I've uploaded them to the website, feel free to contact me.</p>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
