import "./ContactSection.css";
import webIcon from "../assets/icons/web-icon.png";
import emailIcon from "../assets/icons/email-icon.png";
import linkedinLogo from "../assets/icons/linkedin-logo.svg";

export default function ContactSection () {  
    return (
        <section className="contact-section">
            <h1>Let's connect</h1> 
            <h2>Looking for a designer? Contact me</h2>

        <div className="contact-info">
            <div className='contact-info-row'>
                <img src={emailIcon} alt="E-Mail icon" />
                <p>E-Mail: zecluka70@gmail.com</p>
            </div>
            <a href="https://www.linkedin.com/in/luka-zec-a0a821290//">
                <div className='contact-info-row'>
                    <img src={linkedinLogo} alt="Linkedin icon" />
                    <p>LinkedIn: Luka Zec</p>
                </div>
            </a>
            <a href="https://www.lukazec-mediadesign.com">
                <div className='contact-info-row'>
                    <img src={webIcon} alt="Website icon" />
                    <p>Portfolio: www.lukazec-mediadesign.com</p>
                </div>
            </a>
        </div>
        </section>
    );
}



/* <section className="contact-section">
<div className="contact-left-column">
    <h1>Let's talk</h1> 
    <h2>Looking for a designer? Contact me</h2>
    <Form/>
</div>   
<div className="contact-right-column">
    <ContactInfo/>
</div>  
</section> */

