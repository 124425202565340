import "../components/ServiceCard.css"
import { Link } from 'react-router-dom';
import { useState } from "react"


export default function ServiceCard ({src, text, url, originalImage, hoverImage}) {
    const [hover, setHover] = useState(false)

    return (
        <Link to={url} className="service-link">
            <div className='service-card' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <img src={hover ? hoverImage : originalImage} alt="UI/UX Design Icon" className="service-card-icon"></img>
                <img src={hoverImage} alt="UI/UX Design Icon" className="service-card-icon-hover"></img>
                <p>{text}</p>
            </div>
        </Link>
    )
}