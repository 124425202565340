import React from 'react'
import headlineWebDevelopment from "../../assets/headlines/headline-web-dev.svg"
import Footer from '../../components/Footer'
import Subnavigation from '../../components/Subnavigation'
import Thumbnail from '../../components/Thumbnail'
import WebDevelopmentServiceOverview from './WebDevelopmentServiceOverview'

import projectTebaWebsite from "../../assets/project-thumbnails/project-teba-website.jpg"
import projectTalenticWebsite from "../../assets/project-thumbnails/project-talentic-website.jpg"
import projectSport1Website from "../../assets/project-thumbnails/project-sport1-website.jpg"
import arrowDown from "../../assets/icons/arrow-down.svg";

export default function WebDevelopment() {
  return (
    <div className="page-container">
        <div className='service-section'>
            <img src={headlineWebDevelopment} alt="Web Development - Powered by Talentic" className='headline'></img>
            <Subnavigation />
            <div className="thumbnail-container">                
                <Thumbnail image={projectTebaWebsite} alt="Project TeBa Elektrotechnik website" projectName="TeBa Elektrotechnik" projectUrl="project-teba-website" projectType="Small website"/>
                <Thumbnail image={projectTalenticWebsite} alt="Project Talentic website" projectName="Talentic" projectUrl="project-talentic-website" projectType="Website" />
                <Thumbnail image={projectSport1Website} alt="Project Sport1" projectName="Sport1" projectUrl="project-sport1-website" projectType="Landing page"/>
            </div>
        </div>
        <img src={arrowDown} className="arrow-down"/>
        <WebDevelopmentServiceOverview/>
        <Footer/>
    </div>
  )
}
