import mediaDesignHeadline from "../assets/headlines/media-design-headline-luka.png"
import UIUXDesignIcon from "../assets/icons/ui-ux-design-icon.png"
import UIUXDesignIconHovered from "../assets/icons/ui-ux-design-icon-gradient.png"
import webDevIcon from "../assets/icons/web-development-icon.png"
import webDevIconHovered from "../assets/icons/web-development-icon-gradient.png"
import graphicDesignIcon from "../assets/icons/graphic-design2-icon.png"
import graphicDesignIconHovered from "../assets/icons/graphic-design-icon-gradient.png"
import brandIdentityIcon from "../assets/icons/brand-identity-icon.png"
import brandIdentityIconHovered from "../assets/icons/brand-identity-icon-gradient.png"
import arrowDown from "../assets/icons/arrow-down.svg";

import './HeroSection.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ServiceCard from '../components/ServiceCard'
import WebDevelopment from "../pages/web development/WebDevelopment"
import UIUXDesign from "../pages/ui ux design/UIUXDesign"
import GraphicDesign from "../pages/graphic design/GraphicDesign"
import BrandIdentity from "../pages/brand identity/BrandIdentity"

export default function HeroSection () {
    return(
        <section className='hero-section'>
            <img src={mediaDesignHeadline} alt="Media Design - Powered by Talentic" className='media-design-headline'></img>
            <div className="service-cards-container">
                <div className="service-cards-flexbox">
                    <ServiceCard
                    originalImage={UIUXDesignIcon}
                    hoverImage={UIUXDesignIconHovered}
                    text="UI/UX Design"
                    url="ui-ux-design"/>
                    <ServiceCard
                    originalImage={webDevIcon}
                    hoverImage={webDevIconHovered}
                    text="Web Development"
                    url="web-development"/>
                </div>

                <div className="service-cards-flexbox">
                    <ServiceCard
                    originalImage={graphicDesignIcon}
                    hoverImage={graphicDesignIconHovered}
                    text="Graphic Design"
                    url="graphic-design"/>
                    <ServiceCard
                    originalImage={brandIdentityIcon}
                    hoverImage={brandIdentityIconHovered}
                    text="Brand Identity"
                    url="brand-identity"/>   
                </div>
            </div>
            <img src={arrowDown} className="arrow-down"/>
        </section>
    )
}