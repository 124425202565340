import React from 'react'
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./GraphicDesignProjects.css";
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import DogTodayLogo from "../../../assets/dogtoday-logo.png";
import DogTodayFlyer1 from "../../../assets/project-assets/dogtoday-assets/dogtoday-flyer-1.png";
import DogTodayFlyer2 from "../../../assets/project-assets/dogtoday-assets/dogtoday-flyer-2.png";
import DogTodayVisitcard from "../../../assets/project-assets/dogtoday-assets/dogtoday-visitcard.png";
import DogTodayGewinnspiel from "../../../assets/project-assets/dogtoday-assets/dogtoday-gewinnspiel.png";


export default function GraphicDesignProject2() {
  return (
    <div className='graphic-design-project' id="graphic-design-project-2">
    <h1>Graphic Design Project</h1>
      <ProjectOverviewSection
        logo={DogTodayLogo}
        desc="Dog Today is a shop for dog accessories and food in Munich, Germany. The project included the design and development of various print media for the shop. The goal was to create a consistent and appealing design that reflects the shop's brand identity."
        preview=""/>

        <section id="graphic-design">
            <div className="minimal-project-section-container">
                <img src={DogTodayFlyer1} className="project-image" alt="Mythical Shiga persona 1" />
                <img src={DogTodayFlyer2} className="project-image" alt="Mythical Shiga persona 1" />
                <img src={DogTodayGewinnspiel} className="project-image" alt="Mythical Shiga persona 1" />
                <img src={DogTodayVisitcard} className="project-image" alt="Mythical Shiga persona 1" />     
            </div>
        </section>
        <CTASection heading="Interested in working together?" text="Contact me" url="/contact"/>
        <Footer/>
    </div>
    )
}