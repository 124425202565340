import "./AboutSection.css";
import portraitPhoto from "../assets/portrait-photo.jpg";

export default function AboutSection () {
    return(
        <section className="about-section">
            <img src={portraitPhoto} className="portrait-photo" alt="Luka Zec portrait photo"/>
            <div className="about-text">
                <h1>Luka Zec</h1> 
                <h2>UI/UX and Media Designer</h2>
                <p> Looking for an associate in all aspects of digital and print design? You've come to the right place. Specialising in UI/UX design and Webflow Development and with experience in graphic and brand identity design, I look forward to assisting you in finding a solution perfectly tailored for your business needs. </p>
            </div>      
        </section>
    )
}