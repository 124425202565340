import React from 'react'
import "./UIUXServiceOverview.css";
import OpenCard from '../../components/OpenCard';
import CheckmarkIcon from '../../assets/icons/checkmark-icon.png';
import UXResearchIcon from '../../assets/icons/ux-research-icon.png';
import InformationArchitectureIcon from '../../assets/icons/information-architecture-icon.png';
import UserFlowIcon from '../../assets/icons/user-flow-icon.png';
import WireframingPrototypingIcon from '../../assets/icons/wireframing-prototyping-icon.png';
import AccessibilityIcon from '../../assets/icons/accessibility-icon.png';
import UXWritingIcon from '../../assets/icons/ux-writing-icon.png';
import TestingEvaluationIcon from '../../assets/icons/testing-evaluation-icon.png';
import UserInterfaceDesignIcon from '../../assets/icons/user-interface-design-icon.png';
import UIUXDesignIconHovered from '../../assets/icons/ui-ux-design-icon-gradient.png';

export default function UIUXServiceOverview() {
  return (
    <div className='service-overview-container'>
      <div className='service-overview-title'>
        <img src={UIUXDesignIconHovered} alt="Checkmark Icon" className="service-overview-icon" />
        <h1>My approach to UI/UX design </h1>
      </div>
      <p className='service-overview-text'> With my user-centered UI/UX design approach, I focus on developing aesthetically pleasing UIs for your website or application, with usability and user-friendlienss as the foundation for an overall delightful user experience. The final solution will be both authentic and eye-catching but also accessible for all users. Each stage of the UX/UI design process also comes with artifacts & deliverables relevant to the type and scope of project, as outlined below.</p>
      <div className='open-cards-container'>
        <OpenCard name="UX research" img={UXResearchIcon} items={["Target audience analysis", "Use context & requirements", "User personas", "Empathy & journey mapping"]}/>
        <OpenCard name="Information architecture" img={InformationArchitectureIcon} items={["Card sorting", "Micro information architecture", "Macro information architecture", "Content categorization"]}/>
        <OpenCard name="User journey/flows" img={UserFlowIcon} items={["User journey mapping", "User flowcharts", "Low-fidelity wireflows"]}/>
        <OpenCard name="Wireframing and prototyping" img={WireframingPrototypingIcon} items={["Low-fidelity wireframes", "High-fidelity prototypes"]}/>
        <OpenCard name="User interface design" img={UserInterfaceDesignIcon} items={["Modern UI design", "Authentic visual design", "Design system", "Usability / accessibility"]}/>
        <OpenCard name="Usability/accessibility" img={AccessibilityIcon} items={["Usability heuristics", "Accessibility standards", "Responsive design"]}/>
        <OpenCard name="UX Writing" img={UXWritingIcon} items={["Micro- and Macrocopy", "Content strategy", "Search engine optimization"]}/>
        <OpenCard name="Testing and evaluation" img={TestingEvaluationIcon} items={["Cognitive walkthroughs", "Heuristic evaluation", "Web analytics tracking", "Usability testing"]}/>
      </div>
    </div>
  )
}
