import React from 'react'
import "../Pages.css"
import headlineGraphicDesign from "../../assets/headlines/headline-graphic-design.svg"
import Footer from '../../components/Footer'
import Subnavigation from '../../components/Subnavigation'
import Thumbnail from '../../components/Thumbnail'
import GraphicDesignServiceOverview from './GraphicDesignServiceOverview'

import projectDogToday from "../../assets/project-thumbnails/project-dogtoday.jpg"
import projectTalenticSocialMedia from "../../assets/project-thumbnails/project-talentic-socialmedia.jpg"
import projectTalenticServiceCatalogue from "../../assets/project-thumbnails/project-talentic-servicekatalog.jpg"
import arrowDown from "../../assets/icons/arrow-down.svg";

export default function GraphicDesign() {
  return (
    <div className="page-container">
        <div className='service-section'>
            <img src={headlineGraphicDesign} alt="Graphic Design - Powered by Talentic" className='headline'></img>
            <Subnavigation />
            <div className="thumbnail-container">                
                <Thumbnail image={projectDogToday} alt="Project Dog Today - print media" projectName="Dog Today" projectUrl="project-dogtoday-print-media" projectType="Print media"/>
                <Thumbnail image={projectTalenticSocialMedia} alt="Project Talentic - social media" projectName="Talentic" projectUrl="project-talentic-social-media" projectType="Social media" />
                <Thumbnail image={projectTalenticServiceCatalogue} alt="Project Talentic - service catalog" projectName="Talentic" projectUrl="project-talentic-service-catalog" projectType="Service catalog"/>
            </div>
        </div>
        <img src={arrowDown} className="arrow-down"/>
        <GraphicDesignServiceOverview/>
        <Footer/>
    </div>
  )
}
