import React from 'react'
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./GraphicDesignProjects.css";
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import TalenticLogo from "../../../assets/talentic-logo.png";
import TalenticSocialMedia1 from "../../../assets/project-assets/talentic-assets/talentic-social-media-1.jpg";
import TalenticSocialMedia2 from "../../../assets/project-assets/talentic-assets/talentic-social-media-2.png";
import TalenticSocialMedia3 from "../../../assets/project-assets/talentic-assets/talentic-social-media-3.jpg";

export default function GraphicDesignProject1() {
  return (
    <div className='graphic-design-project' id="graphic-design-project-1">
    <h1>Graphic Design Project</h1>
      <ProjectOverviewSection
        logo={TalenticLogo}
        desc="Talentic GmbH is a specialized personnel service for recruiting on demand, HR management and talent marketing from Munich, Germany. This project included the redesign of their social media presence according to their newly developing corporate identity, with robust & reusable templates at the core of the campaign."
        preview=""/>

        <section id="graphic-design">
          <div className="minimal-project-section-container">
            <img src={TalenticSocialMedia1} className="project-image" alt="Talentic social media post designs 1" />
            <p>The redesign of Talentic's social media presence included creating custom miscellaneous posts that utilize minimalistic illustrations and typography to communicate job postings, special occasions, information etc. </p>
            <img src={TalenticSocialMedia2} className="project-image" alt="Talentic social media post designs 1" />
            <p>Another aspect of their social media redesign was creating posts which redirect Instagram users to Talentic's Website Blog feature. This included showcasing teasers and previews of featured blog articles.</p>
            <img src={TalenticSocialMedia3} className="project-image" alt="Talentic social media post designs 1" />
            <p>With new coworkers arriving, developing and achieving milestones, a set of templates for welcome posts, certain anniversaries and similar unique events was needed to create a welcoming and personal impression on Talentic's social media channels.</p>
            </div>
        </section>
        <CTASection heading="Interested in working together?" text="Contact me" url="/contact"/>
        <Footer/>
    </div>
    )
}
