import React from 'react'
import { useRoutes, Link, useParams } from "react-router-dom";
import Subnavigation from '../../components/Subnavigation';
import Footer from '../../components/Footer';
import Thumbnail from '../../components/Thumbnail';

import headlineUIUXDesign from "../../assets/headlines/headline-ui-ux-design.svg"
import projectTalenticWebsite from "../../assets/project-thumbnails/project-talentic-website.jpg"
import projectShiga from "../../assets/project-thumbnails/project-mythical-shiga.png"
import projectTinkersforge from "../../assets/project-thumbnails/project-tinkersforge-website.jpg"
import UIUXServiceOverview from './UIUXServiceOverview';
import arrowDown from "../../assets/icons/arrow-down.svg";



export default function UIUXDesign() {
  return (
    <div className="page-container">
        <div className='service-section'>
            <img src={headlineUIUXDesign} alt="UI/UX Design - Powered by Talentic" className='headline'></img>
            <Subnavigation />
            <div className="thumbnail-container">                
                <Thumbnail image={projectShiga} alt="Project Mythical Shiga" projectName="Mythical Shiga" projectUrl="project-shiga-ui-ux" projectType="App"/>
                <Thumbnail image={projectTalenticWebsite} alt="Project Talentic - website" projectName="Talentic" projectUrl="project-talentic-ui-ux" projectType="Website"/>
                <Thumbnail image={projectTinkersforge} alt="Project Tinker's Forge - website" projectName="Tinker's Forge" projectUrl="project-tinkersforge-ui-ux" projectType="Web-App"/>
            </div>
        </div>
        <img src={arrowDown} className="arrow-down"/>
        <UIUXServiceOverview/>
        <Footer/>
    </div>
  )
}
