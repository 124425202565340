import AboutSection from "../sections/AboutSection"
import SkillsSection from "../sections/SkillsSection"
import CTASection from "../components/CTASection"
import Footer from "../components/Footer"

export default function About(){
    return (
        <div className="page-container">
            <AboutSection/>
            <SkillsSection/>
            <CTASection heading="Interested in seeing my work?" text="View Portfolio" url="/ui-ux-design"/>
            <Footer/>
        </div>
    )
 }