import React from 'react'
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./WebDevelopmentProjects.css";
import TebaLogo from "../../../assets/teba-logo.png";
import ProjectTebaWebsitePreview from "../../../assets/project-previews/project-teba-website-preview.png"
import ProjectOutline from '../../../components/ProjectOutline';
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import TebaPersona1 from "../../../assets/project-assets/teba-assets/teba-persona-1.png";
import TebaPersona2 from "../../../assets/project-assets/teba-assets/teba-persona-2.png";
import TebaInformationArchitecture from "../../../assets/project-assets/teba-assets/teba-information-architecture.png";
import TebaPrototypeMobile from "../../../assets/project-assets/teba-assets/teba-prototype-mobile.jpg";
import TebaPrototypeDesktop from "../../../assets/project-assets/teba-assets/teba-prototype-desktop.png";
import TebaCopywriting from "../../../assets/project-assets/teba-assets/teba-copywriting.png";
import TebaDesignGuide from "../../../assets/project-assets/teba-assets/teba-design-guide.svg";
import TebaMockupMobile from "../../../assets/project-assets/teba-assets/teba-mockup-mobile.png";
import TebaWireframingPrototyping from "../../../assets/project-assets/teba-assets/teba-wireframing-prototyping.png";
import AccessibilityIconColor from "../../../assets/icons/accessibility-icon-color.png";
import WebflowImage from "../../../assets/project-assets/teba-assets/webflow-image.png";

export default function WebDevelopmentProject1() {
  return (
    <div className='web-development-project' id="web-development-project1">
    <h1>Web Development Project</h1>
      <ProjectOverviewSection
        logo={TebaLogo}
        desc="TeBa Elektrotechnik GmbH is a company from Munich, Germany which offers services in all aspects of electrical engineering. They desperately needed a new updated web presence that reflects the nature and quality of their services while conveying all relevant information on a single landing page."
        preview={ProjectTebaWebsitePreview}/>
      <ProjectOutline  color="#00B2FF" title="Case study: TeBa website" numbers = {["01", "02", "03", "04"]} chapters={["User experience", "User Interface Design", "Mobile responsiveness", "Webflow Development"]}/>
    
      <section id="user-experience">
        <div className="project-section-container">
          <h2><span className="teba-color">01</span> User Experience</h2>
          <div className="project-segment">
            <h3>Briefing, research & analysis</h3>
            <p>The first phase of the website redesign was to analyse TeBa's competitors in the area and derive common design and content strategy patterns that would effectively communicate TeBa's services and values while aligning with users' expectations. More about this in our UI/UX Design service.</p>
          </div>
          <div className="project-segment">
            <h3>User personas</h3>
            <p>The challenge was in defining and mediating between two very different target audiences: Private customers (B2C) and other businesses (B2B). The website therefore needed to maintain a neutral character with a clear presentation of all offered services.</p>
            <img src={TebaPersona1} className="project-image" alt="TeBa Elektrotechnik persona 1" />
            <img src={TebaPersona2} className="project-image" alt="TeBa Elektrotechnik persona 1" />
          </div>
          <div className="project-segment">
            <h3>Information architecture</h3>
            <p>The solution to TeBa's goals and aspirations could be found in a simple one-pager , which is perfectly suitable for conveying the mission of a small business of this scope. There was no need for complexity and multiple pages.</p>
            <img src={TebaInformationArchitecture} className="project-image" alt="TeBa Elektrotechnik information architecture" />
          </div>
          <div className="project-segment">
            <h3>Wireframing & prototyping</h3>
            <p>The wireframing process includes developing simple, low-fidelity sketches of the overall page layout and information structure - in constant communication with the client. This ensures full understanding and precision about the general idea, goals & desires before moving on to more resource-intensive stages of development. </p>
            <p>Prototyping takes this agreed upon base to the next level, by designing an interactive click-dummy for the client to test. Only after the wireframes and prototypes have been meticulously tested, evaluated and optimized does the final design go into development. This prevents unnecessary wasting of time and resources in later stages where changes are harder to make. </p>
            <img src={TebaWireframingPrototyping} className="project-image" alt="TeBa Elektrotechnik wireframing and prototyping process" />
          </div>
          <div className="project-segment">
            <h3>UX copyriting</h3>
            <p>TeBa's visual web presence had to be supplemented with a compact but compelling written description of their offered services which motivates visitors to take desirable actions.</p>
            <img src={TebaCopywriting} className="project-image" alt="TeBa Elektrotechnik copywriting" />
          </div>
          <div className="project-segment">
            <h3>Usability & Accessibility</h3>
            <div className="segment-columns">
                <p>To make TeBa's website an enjoyable user experience that is accessible to all users regardless of visual and motor disabilities, I have laid great importance in adhering to official accessibility & usability standards. Most notably, Google Material Design guidelines,W3C Web Content Accessibility Guidelines (WCAG) & various Usability Heuristics ensure that the website remains robust, perceivable, operable and understandable to all.</p>
                <img className="segment-columns-icon" src={AccessibilityIconColor} alt="Accessibility icon" />
            </div>
          </div>
            <CTASection className="CTASection" heading="Interested in seeing more?" text="UI/UX Design" url="/ui-ux-design"/>
        </div>
      </section>

      <section id="user-interface-design">
        <div className="project-section-container">
          <h2><span className="teba-color">02</span> User interface design</h2>
          <div className="project-segment">
            <h3>Design guide</h3>
            <p>To ensure consistent development and maintenance of the digital user interface, you will receive a comprehensive design guide for the website/app. This includes things like the color palette, typography stylings, icon styles, basic UI building blocks etc.</p>
            <img src={TebaDesignGuide} className="project-image" alt="Tea Elektrotechnik design guide" />
          </div>
          <div className="project-segment">
            <h3>High-fidelity UI prototype</h3>
            <p>Here's a screenshot of the final UI prototype that resulted from all the previous steps and considerations. This is the 1:1 design of the website that then goes into development.</p>
            <img src={TebaPrototypeDesktop} className="project-image" alt="TeBa Elektrotechnik high-fidelity UI prototype" />
          </div>
        </div>
      </section>

      <section id="mobile-responsiveness">
        <div className="project-section-container">
          <h2><span className="teba-color">03</span> Mobile responsiveness</h2>
          <div className="project-segment">
            <p>To ensure the website's responsiveness across all systems & devices, I've dedicated special attention to designing the mobile version of the website.</p>
            <img src={TebaMockupMobile} className="project-image" alt="TeBa Elektrotechnik website mobile mockup" />
          </div>
        </div>
      </section>

      <section id="webflow-development">
        <div className="project-section-container">
          <h2><span className="teba-color">04</span> Webflow Development</h2>
          <div className="project-segment">
            <p>The final design was ultimately developed into a responsive website using Webflow. This step also included extra support services such as ongoing site maintenance and basic Search Engine Optimization (SEO).</p>
            <img src={WebflowImage} className="project-image" alt="Webflow logo image" />
          </div>
        </div>
      </section>
      <CTASection heading="Interested in working together?" text="Contact me" url="/contact"/>
      <Footer/>
    </div>
  )
}

