import React from 'react'
import './ProjectOutline.css'

export default function ProjectOutline({title, chapters, numbers, color}) {
  return (
    <div className='project-outline'>
      <h2>{title}</h2>
      <ul>
        {chapters.map ((chapter, index) => 
            <li>
                <a href={`#${chapter.toLowerCase().replace(/ /g, '-')}`}>
                  <span className="numbers" style={{color: color}}>{numbers[index]}</span> <span className="chapters">{chapter}</span>
                </a>
            </li>
        )}
      </ul>
    </div>
  )
}
