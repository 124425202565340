import React from 'react'
import "./GraphicDesignServiceOverview.css";
import OpenCard from '../../components/OpenCard';
import CheckmarkIcon from '../../assets/icons/checkmark-icon.png';
import PrintMediaIcon from '../../assets/icons/print-media-icon.png';
import SocialMediaIcon from '../../assets/icons/social-media-icon.png';
import MarketingMaterialIcon from '../../assets/icons/marketing-material-icon.png';

export default function GraphicDesignServiceOverview() {
  return (
    <div className='service-overview-container'>
      <div className='service-overview-title'>
        <img src={CheckmarkIcon} alt="Checkmark Icon" className="service-overview-icon" />
        <h1>What you can expect</h1>
      </div>
      <p className='service-overview-text'>Explore the endless possibilities of cross-media marketing with custom graphic design solutions for both digital and print channels that will harmoniously work with your existing or a newly developing corporate identity. </p>
      <div className='open-cards-container'>
        <OpenCard name="Print media" img={PrintMediaIcon} items={["Posters, flyers & brochures", "Business cards", "Catalogues & booklets"]}/>
        <OpenCard name="Social media" img={SocialMediaIcon} items={["Posts & stories", "Banners & avatars", "Job postings"]}/>
        <OpenCard name="Cross-media marketing" img={MarketingMaterialIcon} items={["Ad campaigns", "Product packaging", "Short ad videos"]}/>
      </div>
    </div>
  )
}
