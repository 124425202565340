import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import "./Subnavigation.css";
import WebDevelopment from '../pages/web development/WebDevelopment';
import UIUXDesign from '../pages/ui ux design/UIUXDesign';
import GraphicDesign from '../pages/graphic design/GraphicDesign';
import BrandIdentity from '../pages/brand identity/BrandIdentity';

export default function Subnavigation() {
    const location = useLocation();

    return (
        <div className='subnavigation'>
            <NavLink to="/ui-ux-design" className="subnavigation-link" activeClassName="active">UI/UX design</NavLink>
            <NavLink to="/web-development" className="subnavigation-link" activeClassName="active">Web development</NavLink>
            <NavLink to="/graphic-design" className="subnavigation-link" activeClassName="active">Graphic design</NavLink>
            <NavLink to="/brand-identity" className="subnavigation-link" isActive={() => location.pathname === '/brand-identity'}>Brand identity</NavLink>
        </div>
    );
}
