import React from 'react'
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import ProjectShigaPreview from "../../../assets/project-previews/project-shiga-preview.png";
import "./UIUXDesignProjects.css";
import ShigaLogo from "../../../assets/shiga-logo.png";
import ProjectOutline from '../../../components/ProjectOutline';
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import ShigaPersonas from "../../../assets/project-assets/shiga-assets/shiga-personas.png";
import ShigaEmpathyMaps from "../../../assets/project-assets/shiga-assets/shiga-empathy-maps.png";
import ShigaUserFlow from "../../../assets/project-assets/shiga-assets/shiga-user-flow.png";
import ShigaInformationArchitecture from "../../../assets/project-assets/shiga-assets/shiga-information-architecture.png";
import ShigaPrototyping from "../../../assets/project-assets/shiga-assets/shiga-prototyping.jpg";
import ShigaWireframing from "../../../assets/project-assets/shiga-assets/shiga-wireframing.png";
import ShigaOverview from "../../../assets/project-assets/shiga-assets/shiga-overview.jpg";
import ShigaCopywriting from "../../../assets/project-assets/shiga-assets/shiga-copywriting.png";
import ShigaStyleTile from "../../../assets/project-assets/shiga-assets/shiga-style-tile.png";
import ShigaDesignGuide from "../../../assets/project-assets/shiga-assets/shiga-design-guide.svg";
import ShigaUIMockup from "../../../assets/project-assets/shiga-assets/shiga-ui-mockup.png";
import ShigaUserJourneyMap from "../../../assets/project-assets/shiga-assets/shiga-user-journey-map.jpg";
import ShigaRequirementsDefinition from "../../../assets/project-assets/shiga-assets/shiga-requirements-definition.png";
import ShigaKeyFeatures from "../../../assets/project-assets/shiga-assets/shiga-key-features.png";

import CognitiveWalkthroughIcon from "../../../assets/icons/cognitive-walkthrough-icon.png";
import HeuristicEvaluationIcon from "../../../assets/icons/heuristic-evaluation-icon.png";
import AccessibilityIconColor from "../../../assets/icons/accessibility-icon.png";
import UsabilityTestsIcon from "../../../assets/icons/usability-tests-icon.png";
import UsabilityReportIcon from "../../../assets/icons/usability-report-icon.png";

export default function UIUXDesignProject1() {
  return (
    <div className='ui-ux-design-project' id="ui-ux-design-project-1">
    <h1>UI/UX Design Project</h1>
      <ProjectOverviewSection
        logo={ShigaLogo}
        desc="The Mythical Shiga app project included the design of a guide-app for a tourist region in Japan. The main focus was on incorporating interactive augmented reality (AR) features which allow the user to get to know Shiga through a gamified system of gathering lore collectibles."
        preview={ProjectShigaPreview}/>
      <ProjectOutline color="#D63058" title="Case study: Mythical Shiga App" numbers ={["01", "02", "03", "04"]} chapters={["Context of use analysis", "Requirements definition", "Conceptualisation & design", "Evaluation & optimization"]}/>
      
      <section id="context-of-use-analysis">
        <div className="project-section-container">
          <h2><span className="shiga-color">01</span> Context of use analysis</h2>
          <div className="project-segment">
            <h3>Research & status quo analysis</h3>
            <p>The first phase of the project was based on understanding the overall project context and objectives while observing the current situation on the market through quantitative and qualitative research, while analysing the opportunities, strenghts and weaknesses of developing such an application.</p>
          </div>
          <div className="project-segment">
            <h3>Target audience analysis - interviews & user personas</h3>
            <p>In order to define the context of use, gather user requirements and find the appropriate visual language for users, the next step was to define and analyse the target audience. Insights gained from a handful of carefully curated user interviews were then translated into user personas.</p>
            <img src={ShigaPersonas} className="project-image" alt="Mythical Shiga persona 1" />
          </div>
          <div className="project-segment">
            <h3>User empathy mapping</h3>
            <p>Developing user empathy maps derived from those qualitative insights allowed me to more meaningfully empathize with the target audience and understand their thoughts, feelings and pain-points as a basis for ideating and conceptualizing solutions.</p>
            <img src={ShigaEmpathyMaps} className="project-image" alt="Mythical Shiga empathy map 2" />
          </div>
          <div className="project-segment">
            <h3>User journey mapping</h3>
            <p>With these insights, I could then introduce the Mythical Shiga app and postulate how it could serve as a solution to their desire to explore and discover Shiga through a gamified experience, by inserting the app as a travel companion which provides the user with features that aims to enchance their travelling experience. </p>
            <img src={ShigaUserJourneyMap} className="project-image" alt="Mythical Shiga user journey map" />
          </div>
        </div>
      </section>

      <section id="defining-reqirements">
        <div className="project-section-container">
          <h2><span className="shiga-color">02</span> Requirements definition</h2>
          <div className="project-segment">
            <h3>Defining UX requirements</h3>
            <p>Through the initial briefing, continuous observations, analysis and additional brainstorming, I've gathered and categorized the necessary business, user and system requirements the app must satisfy in order to become a financially viable product for share- and stakeholders and provide a well-rounded experience for all users. The below illustration is merely a general snapshot of everything that was considered in this process.</p>
            <img src={ShigaRequirementsDefinition} className="project-image" alt="Mythical Shiga requirements definition" />
          </div>
          <div className="project-segment">
            <h3>Defining key features</h3>
            <p>The defined requirements guided me in outlining the core features of the Mythical Shiga app. These include the interactive map menu as the central navigational tool, the augmented reality feature as a means of interacting with the places the user travels to, as well as the ability to collect and manage lore collectibles found with the help of AR. </p>
            <img src={ShigaKeyFeatures} className="project-image" alt="Mythical Shiga key features definition" />
          </div>
        </div>
      </section>

      <section id="conceptualisation-&-design">
        <div className="project-section-container">
          <h2><span className="shiga-color">03</span> Conceptualization & design</h2>
          <div className="project-segment">
            <h3>Information architecture</h3>
            <p>With key features and requirements in mind, the solution to the app's information structure could be found in a relatively linear architecture, stemming from the interactive map as the main navigational menu and branching onto the individual locations and their collectible items.</p>
            <img src={ShigaInformationArchitecture} className="project-image" alt="Mythical Shiga information architecture" />
          </div>
          <div className="project-segment">
            <h3>User flow diagrams</h3>
            <p>Creating user flow diagrams allowed me to visualise the necessary steps a user is expected to take to finish a specific taxt within the app - in this case, the user finding, collecting and viewing a lore collectible.</p>
            <img src={ShigaUserFlow} className="project-image" alt="Mythical Shiga user flow diagram" />
          </div>
          <div className="project-segment">
            <h3>Wireframing</h3>
            <p>The wireframing process included developing simple, low-fidelity sketches of various app screens. This allowed me to quickly conceptualize the possible layout and micro information architecture of each screen before moving on to more resource-intensive stages of development.</p>
            <img src={ShigaWireframing} className="project-image" alt="Mythical Shiga wireframing process" />
          </div>
          <div className="project-segment">
          <div className="project-segment">
            <h3>UX writing</h3>
            <p>The app's user interface design had to be supplemented with persuasive and compelling written content that engages the target audience, communicates messages effectively, and allows users to discover key features. The 3 onboarding screens are a perfect example of effective use of UX copy.</p>
            <img src={ShigaCopywriting} className="project-image" alt="Mythical Shiga copywriting" />
          </div>
          <div className="project-segment">
            <h3>Usability & accessibility</h3>
            <div className="segment-columns">
                <p>To make Mythical Shiga an enjoyable user experience that is accessible to all users regardless of visual and motor disabilities, I have laid great importance in adhering to official accessibility & usability standards. Most notably, Google Material Design guidelines,W3C Web Content Accessibility Guidelines (WCAG) & various Usability Heuristics ensure that the website remains robust, perceivable, operable and understandable to all.</p>
                <img src={AccessibilityIconColor} className="segment-columns-icon" alt="" />
            </div>
          </div>
          <div className="project-segment">
            <h3>Moodboard (Style tiles)</h3>
            <p>The first impulses and impressions of the potential visual design of the app is initially expressed through a moodboard/style tile which contains the look and feel of the most basic UI building blocks.</p>
            <img src={ShigaStyleTile} className="project-image" alt="" />
          </div>
          <div className="project-segment">
            <h3>Style guide</h3>
            <p>To ensure consistent development and maintenance of the digital user interface, I've made a comprehensive design guide for the Myhical Shiga app. This included things like the color palette, typographic stylings, iconography and layout.</p>
            <img src={ShigaDesignGuide} className="project-image" alt="" />
          </div>
          <h3>High-fidelity prototyping</h3>
            <p> The final step was designing interactive high-fidelity prototypes for the client to test. These represent the 1:1 user interface design that, once sufficiently reviewed, tested and optimized, could be finally sent to development.  </p>
            <img src={ShigaPrototyping} className="project-image" alt="Mythical Shiga prototyping process" />
            <img src={ShigaUIMockup} className="project-image" alt="" />
          </div>
        </div>
      </section>

      <section id="evaluation-&-optimization">
        <div className="project-section-container">
          <h2><span className="shiga-color">03</span> Evaluation & optimization</h2>
          <div className="project-segment">
            <h3>Heuristic evaluation</h3>
            <div className="segment-columns">
              <p>Evaluating the Mythical Shiga app against a set of prominent usability heuristics helped me ensure that the application remains usable, operable and robust in all use-case scenarios and adheres to modern usability standards.</p>
              <img className="segment-columns-icon" src={HeuristicEvaluationIcon} alt="" />
            </div>
            <h3>Usability tests</h3>
            <div className="segment-columns">
              <p>The indispensable step of testing the prototype with actual users was conducted in the final stages in order to identify problems and discover possible opportunities. Due to budget and time constraints, a sample of only 3 participants could be observed conducting given tasks, nonetheless gathering valuable insights into their behaviour and pain-points.  </p>
              <img className="segment-columns-icon" src={UsabilityTestsIcon} alt="" />
            </div>
            <h3>Cognitive walkthrough</h3>
            <div className="segment-columns">
              <p>Due to the limited resources for testing with real users, this usability inspection method allowed me to walk through specific scenarios with the traits, goals and needs of specific user personas in mind. This allowed me to test functionality and user experience while empathizing with the users.</p>
              <img className="segment-columns-icon" src={CognitiveWalkthroughIcon} alt="" />
            </div>
            <h3>Usability report & key takeaways</h3>
            <div className="segment-columns">
              <p>The overall usability review was documented in a usability report highlighting key takeaways and optimization suggestions which were then iteratively applied to existing designs. Results primarily showed problems in interacting with discovering the AR feature and navigating between the different collection pages.  </p>
              <img className="segment-columns-icon" src={UsabilityReportIcon} alt="" />
            </div>
          </div>

          
        </div>
      </section>
      <CTASection heading="Interested in working together?" text="Contact me" url="/contact"/>
      <Footer/>
    </div>
  )
}
