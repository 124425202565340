import "./SkillList.css"

export default function SkillList ({ items, title }) {
    return(
        <div className="skill-list">
            <h2>{title}</h2>
            <ul>
                {items.map ((item, index) =>
                    <li key={index}>
                        <img src={item.imageUrl}/>
                        {item.name}
                    </li>
                    )}
            </ul>
        </div>
    )
} 