import React from 'react'
import "./Thumbnail.css"
import { NavLink } from 'react-router-dom'

export default function Thumbnail({image, alt, projectName, projectUrl, projectType}) {
  return (
    <NavLink to={projectUrl} className="thumbnail-link">
      <div className='thumbnail'>
          <img src={image} alt={alt} className='thumbnail-image'></img>
          <div className='thumbnail-desc'>
              <p>{projectName}</p>
              <p>{projectType}</p>
          </div>
      </div>
    </NavLink>
  )
}
