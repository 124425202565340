import React from 'react';
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./UIUXDesignProjects.css";
import TalenticLogo from "../../../assets/talentic-logo.png";
import ProjectTalenticWebsitePreview from "../../../assets/project-previews/project-talentic-website-preview.png";
import ProjectOutline from '../../../components/ProjectOutline';
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import TalenticInformationArchitecture from "../../../assets/project-assets/talentic-assets/talentic-information-architecture.png";
import TalenticUserFlow from "../../../assets/project-assets/talentic-assets/talentic-user-flow.png";
import TalenticWireframes from "../../../assets/project-assets/talentic-assets/talentic-wireframes.png";
import TalenticPrototyping from "../../../assets/project-assets/talentic-assets/talentic-prototyping.jpg";
import TalenticCopywriting from "../../../assets/project-assets/talentic-assets/talentic-copywriting.png";
import TalenticDesignGuide from "../../../assets/project-assets/talentic-assets/talentic-style-guide.svg";
import TalenticPrototype1 from "../../../assets/project-assets/talentic-assets/talentic-prototype-1.png";
import TalenticPrototype2 from "../../../assets/project-assets/talentic-assets/talentic-prototype-2.png";
import TalenticPrototype3 from "../../../assets/project-assets/talentic-assets/talentic-prototype-3.png";
import TalenticMockupMobile from "../../../assets/project-assets/talentic-assets/talentic-mockup-mobile.jpg";
import TalenticTargetAudienceAnalysis from "../../../assets/project-assets/talentic-assets/talentic-target-audience-analysis.png";
import TalenticUserPersona1 from "../../../assets/project-assets/talentic-assets/talentic-user-persona-1.png";
import TalenticUserPersona2 from "../../../assets/project-assets/talentic-assets/talentic-user-persona-2.png";
import TalenticUserJourneyMap from "../../../assets/project-assets/talentic-assets/talentic-user-journey-map.jpg";

import CognitiveWalkthroughIcon from "../../../assets/icons/cognitive-walkthrough-icon.png";
import HeuristicEvaluationIcon from "../../../assets/icons/heuristic-evaluation-icon.png";
import AccessibilityIconColor from "../../../assets/icons/accessibility-icon.png";
import UsabilityTestsIcon from "../../../assets/icons/usability-tests-icon.png";
import UsabilityReportIcon from "../../../assets/icons/usability-report-icon.png";

export default function UIUXDesignProject2() {
      return (
            <div className='ui-ux-design-project' id="ui-ux-design-project-2">
                  <h1>UI/UX Design Project</h1>
                  <ProjectOverviewSection
                        logo={TalenticLogo}
                        desc="Talentic GmbH is a specialized personnel service for recruiting on demand, HR management and talent marketing from Munich, Germany. This project included a complete redesign and development of their website based on their newly forming corporate identity. Their primary business goal was to communicate their products and services to both B2B and B2C customers, as well as job-seeking candidates."
                        preview={ProjectTalenticWebsitePreview}
                  />
                  <ProjectOutline color="#187cf7" title="Case study: Talentic website" numbers ={["01", "02", "03", "04"]} chapters={["Context of use analysis", "Requirements definition", "Conceptualisation & design", "Evaluation & optimization"]} />

                  <section id='contect-of-use-analysis'>
                        <div className="project-section-container">
                              <h2><span className="talentic-color">01 </span>Context of use analysis</h2>
                              <div className="project-segment">
                                    <h3>Briefing & status quo analysis</h3>
                                    <p>In order to gather UX requirements for the website, the first phase of the project was based on understanding the overall project context and the business needs and goals of Talentic with several briefing sessions, as well as observing the current situation on the market. Repeated sessions within the marketing-team led to recurring long-term changes and reorientations over the course of 3 years of working with Talentic. </p>
                                    <p>Due to the somewhat complex nature and variety of Talentic's services, it was crucial to effectively communicate what they are about and what they have to offer. The challenge was in mediating between two very different target audiences: HR professionals and candidates while catering to both of their needs & goals.</p>
                              </div>
                              <div className="project-segment">
                                    <h3>Target audience analysis - User personas</h3>
                                    <p> The initial briefing resulted in a general understanding of Talentic's target audience, exemplified in two general categories, which were then further developed into two user personas based on available data and the company's available insights. </p>
                                    <img src={TalenticTargetAudienceAnalysis} className="project-image" alt="Talentic target audience analysis" />
                                    <img src={TalenticUserPersona1} className="project-image" alt="Talentic user persona 1" />
                                    <img src={TalenticUserPersona2} className="project-image" alt="Talentic user persona 2" />
                              </div>
                              <div className="project-segment">
                                    <h3>User journey mapping</h3>
                                    <p>With insights into the target audience, I could then map out the expected (and desired) path of the given persona from their initial desire to achieve their goals, through the discovery of Talentic, their cooperation and hopefully satisfaction.  </p>
                                    <img src={TalenticUserJourneyMap} className="project-image" alt="Talentic user journey mapping" />
                              </div>
                        </div>
                  </section>

                  <section id='requirements-definition'>
                        <div className="project-section-container">
                              <h2><span className="talentic-color">02 </span>Requirements definition</h2>
                              <div className="project-segment">
                              <h3>Defining UX requirements</h3>
                              <p>Through the initial briefing, continuous observations, analysis and recurrent brainstorming with the marketing team, we've gathered and categorized the necessary business, user and system requirements the website must satisfy in order to be financially beneficial for share- and stakeholders and provide a well-rounded experience for all users. The below illustration is merely a general snapshot of everything that was considered in this process.</p>
                              <img src={""} className="project-image" alt="Talentic website requirements definition" />
                        </div>
                        <div className="project-segment">
                              <h3>Defining key features</h3>
                              <p>The requirements defined with the marketing team guided me in outlining the core pages features of Talentic's website. These included dedicated service & general info pages, a download and blog page as well as an externally embedded job board. Some key interactive features included the ability to sign-up, to download files, upload CVs, submit a contact form etc. </p>
                              <img src={""} className="project-image" alt="Talentic website key features definition" />
                        </div>
                        </div>
                  </section>

                  <section id='conceptualisation-&-design'>
                        <div className="project-section-container">
                              <h2><span className="talentic-color">03 </span>Conceptualisation & design</h2>
                              <div className="project-segment">
                                    <h3>Information architecture</h3>
                                    <p> I could then categorize the website's content and key functions using the card sorthing method. The solution to the website's information structure could be found in a relatively simple architecture, with the main navigation linking to key subpages and branching off into two service categories which contain specific services.</p>
                                    <img src={TalenticInformationArchitecture} className="project-image" alt="Talentic information architecture" />
                              </div>
                              <div className="project-segment">
                                    <h3>User flow diagrams</h3>
                                    <p>Our end goal of maximizing conversions was achieved by continually reminding the user about the possibility of personal contact, referencing our services and letting them know where to find crucial information about them.</p>
                                    <img src={TalenticUserFlow} className="project-image" alt="Talentic user flow" />
                              </div>
                              <div className="project-segment">
                                    <h3>Wireframing & prototyping</h3>
                                    <p>The wireframing process included developing simple, low-fidelity sketches of the overall page layout and information structure - in constant communication with the client. This ensures full understanding and precision about the general approach before moving on to more resource-intensive stages of development. </p>
                                    <p>Prototyping takes this agreed upon base to the next level, by designing an interactive click-dummy for the client to test. Only after the wireframes and prototypes have been meticulously tested, evaluated and optimized does the final design go into development. </p>
                                    <img src={TalenticWireframes} className="project-image" alt="Talentic website wireframes" />
                                    <img src={TalenticPrototyping} className="project-image" alt="Talentic website prototyping" />
                              </div>
                              <div className="project-segment">
                                    <h3>Design guide</h3>
                                    <p>To ensure consistent development and maintenance of the digital user interface, I've made a comprehensive design guide for Talentic's website. This included things like the color palette, typographic stylings as well as an icon set adapted to Talentic's CI.</p>
                                    <img src={TalenticDesignGuide} className="project-image" alt="Talentic design guide" />
                              </div>
                              <div className="project-segment">
                                    <h3>UX copywriting</h3>
                                    <p>Talentic's visual web presence had to be supplemented with persuasive and compelling written content that engages and adresses both target audiences, communicates messages effectively, and motivates readers to discover their services and contact Talentic.</p>
                                    <img src={TalenticCopywriting} className="project-image" alt="Talentic copywriting" />
                              </div>
                              <div className="project-segment">
                                    <h3>Usability & Accessibility</h3>
                                    <div className="segment-columns">
                                          <p>To make Talentic's website an enjoyable user experience that is accessible to all users regardless of visual and motor disabilities, I have laid great importance in adhering to official accessibility & usability standards.<br /><br />Most notably, Google Material Design guidelines, W3C Web Content Accessibility Guidelines (WCAG) & various Usability Heuristics ensure that the website remains robust, perceivable, operable and understandable to all.</p>
                                          <img className="segment-columns-icon" src={AccessibilityIconColor} alt="Accesibility icon" />
                                    </div>
                              </div>
                              <div className="project-segment">
                                    <h3>High-fidelity UI prototype</h3>
                                    <p>Here's a few screenshots of the final UI prototype that resulted from all the previous steps and considerations. This is the 1:1 design of the website that then goes into development.</p>
                                    <img src={TalenticPrototype1} className="project-image" alt="Talentic website prototype 1" />
                                    <img src={TalenticPrototype2} className="project-image" alt="Talentic website prototype 1" />
                              </div>
                              <div className="project-segment">
                                    <h3>Mobile responsiveness</h3>
                                    <p>To ensure the website's responsiveness across all systems & devices, I've dedicated special attention to designing the mobile version of the website.</p>
                                    <img src={TalenticMockupMobile} className="project-image" alt="Talentic website mobile mockup" />
                              </div>
                        </div>
                  </section>

                  <section id='evaluation-&-optimization'>
                        <div className="project-section-container">
                        <h2><span className="talentic-color">04 </span>Evaluation & optimization</h2>
                        <div className="project-segment">
                              <h3>Heuristic evaluation</h3>
                              <div className="segment-columns">
                                    <p>Evaluating the Talentic's website against a set of prominent usability heuristics helped me ensure that the website remains usable, operable and robust in all use-case scenarios and adheres to modern usability standards.</p>
                                    <img className="segment-columns-icon" src={HeuristicEvaluationIcon} alt="" />
                              </div>
                              <h3>Usability tests</h3>
                              <div className="segment-columns">
                                    <p>The indispensable step of testing the prototype with actual users was conducted in the final stages in order to identify problems and discover possible opportunities. Due to budget and time constraints, a sample of only 4 internal participants could be observed conducting given tasks, nonetheless gathering valuable insights into their behaviour and pain-points. </p>
                                    <img className="segment-columns-icon" src={UsabilityTestsIcon} alt="" />
                              </div>
                              <h3>Cognitive walkthrough</h3>
                              <div className="segment-columns">
                                    <p>Due to the limited resources for testing with real users, this usability inspection method allowed me to walk through specific scenarios with the traits, goals and needs of specific user personas in mind. This allowed me to test functionality and user experience while empathizing with the users.</p>
                                    <img className="segment-columns-icon" src={CognitiveWalkthroughIcon} alt="" />
                              </div>
                              <h3>Usability reports & key takeaways</h3>
                              <div className="segment-columns">
                                    <p>Recurring long-term usability reviews were documented in various usability reports highlighting key takeaways and optimization suggestions which were then iteratively applied to existing designs. Results primarily showed a lack of ability to cross-navigate between services, occasional problems with text sizes on mobile, some responsiveness issues and problems with aspects of the download section. Long-term maintenance implied several major redesigns of the website over the course of 2 years.  </p>
                                    <img className="segment-columns-icon" src={UsabilityReportIcon} alt="" />
                              </div>
                        </div>
                        </div>
                  </section>
                  <CTASection heading="Interested in working together?" text="Contact me" url="/contact" />
                  <Footer/>
            </div>
      );
}
