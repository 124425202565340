import "./Navbar.css"
import talenticLogo from "../assets/talentic-logo.png"
import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'

export default function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false)
    return(
        <nav className="navbar">
            <div className="hamburger-menu" onClick={() => {
                setMenuOpen(!menuOpen)
            }}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={menuOpen ? "open" : ""}>
            <NavLink to="/" onClick={() => {setMenuOpen(!menuOpen)}}>Home</ NavLink>
            <NavLink to="/contact" onClick={() => {setMenuOpen(!menuOpen)}} >Contact</NavLink>
            <NavLink to="/about" onClick={() => {setMenuOpen(!menuOpen)}}>About</NavLink>
            </ul>
        </nav>
    )
}