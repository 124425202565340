import "./SkillsSection.css"
import SkillListColumns from "../components/SkillListColumns"
import SkillList from "../components/SkillList"

import webDesign from "../assets/icons/web-design-icon.png"
import GraphicDesign from "../assets/icons/graphic-design2-icon.png"
import UXDesign from "../assets/icons/ui-ux-design-icon.png"
import UIDesign from "../assets/icons/ui-design-icon.png"
import WebDevelopment from "../assets/icons/web-development-icon.png"
import UXWriting from "../assets/icons/ux-writing-icon.png"
import MobileDesign from "../assets/icons/mobile-design-icon.png"
import VideoEditing from "../assets/icons/video-editing-icon.png"
import UXResearch from "../assets/icons/ux-research-icon.png"
import WebflowDevelopment from "../assets/icons/webflow-icon.png"
import brandIdentity from "../assets/icons/brand-identity-icon.png"
import LifelongLearning from "../assets/icons/lifelong-learning-icon.png"
import AnalyticalCreativeThinking from "../assets/icons/analytical-creative-thinking-icon.png"
import HTML from "../assets/icons/html-icon.png"
import CSS from "../assets/icons/css-icon.png"
import JavaScript from "../assets/icons/javascript-icon.png"
import React from "../assets/icons/react-icon.png"
import FlagSerbia from "../assets/icons/flags/flag-serbia.png"
import FlagCroatia from "../assets/icons/flags/flag-croatia.png"
import FlagBosnia from "../assets/icons/flags/flag-bosnia.png"
import FlagGermany from "../assets/icons/flags/flag-germany.png"
import FlagUK from "../assets/icons/flags/flag-uk.png"
import AdobeXD from "../assets/icons/xd-icon.png"
import AdobePhotoshop from "../assets/icons/photoshop-icon.png"
import AdobeIllustrator from "../assets/icons/illustrator-icon.png"
import AdobeInDesign from "../assets/icons/indesign-icon.png"
import Figma from "../assets/icons/figma-icon.png"
import DaVinciResolve from "../assets/icons/davinciresolve-icon.png"
import VisualStudioCode from "../assets/icons/visualstudiocode-icon.png"
import GitHub from "../assets/icons/github-icon.png"
import Webflow from "../assets/icons/webflow-icon.png"
import ChatGPT from "../assets/icons/chatgpt-icon.png"


export default function SkillsSection (){
    const skillsArray = [
        {index: 1, name: "User experience design", imageUrl: UXDesign},
        {index: 2, name: "User interface design", imageUrl: UIDesign},
        {index: 3, name: "Webflow development", imageUrl: WebDevelopment},
        {index: 4, name: "UX writing", imageUrl: UXWriting},
        {index: 5, name: "UX research", imageUrl: UXResearch},
        {index: 6, name: "Graphic design", imageUrl: GraphicDesign},
        {index: 7, name: "Brand identity design", imageUrl: brandIdentity},
        {index: 8, name: "Video editing", imageUrl: VideoEditing},
        {index: 9, name: "Analytical & creative thinking", imageUrl: AnalyticalCreativeThinking},
        {index: 10, name: "Curiosity & lifelong learning", imageUrl: LifelongLearning},

    ]

    const technologiesArray = [
        {index: 1, name: "Webflow Development", imageUrl: WebflowDevelopment},
        {index: 2, name: "HTML", imageUrl: HTML},
        {index: 3, name: "CSS", imageUrl: CSS},
        {index: 4, name: "JavaScript (Basic)", imageUrl: JavaScript},
        {index: 5, name: "React.js (Basic)", imageUrl: React},
    ]

    const languagesArray = [
        {index: 1, name: "Serbo-Croatian (Native)", imageUrl: FlagSerbia},
        {index: 2, name: "German (Fluent)", imageUrl: FlagGermany},
        {index: 3, name: "English (Fluent)", imageUrl: FlagUK},
    ]

    const toolsArray = [
        {index: 1, name: "Adobe XD", imageUrl: AdobeXD},
        {index: 2, name: "Adobe Illustrator", imageUrl: AdobeIllustrator},
        {index: 3, name: "Adobe Photoshop", imageUrl: AdobePhotoshop},
        {index: 4, name: "Adobe InDesign", imageUrl: AdobeInDesign},
        {index: 5, name: "Figma", imageUrl: Figma},
        {index: 6, name: "DaVinci Resolve", imageUrl: DaVinciResolve},
        {index: 7, name: "GitHub", imageUrl: GitHub},
        {index: 8, name: "Visual Studio Code", imageUrl: VisualStudioCode},
        {index: 9, name: "Webflow", imageUrl: Webflow},
    ]

    return(
        <section className="skills-section">
            <div className="skills-container">
                <SkillList className="skill-list" title="Skills" items={skillsArray}/>
                <div className="skills-subcontainer">
                    <SkillList className="skill-list" title="Tech stack" items={technologiesArray}/>
                    <SkillList className="skill-list" title="Languages" items={languagesArray}/>
                </div>
            </div>
            <SkillListColumns className="horizontal" title="Tools" items={toolsArray}/>
        </section>
    )
}