import HeroSection from '../sections/HeroSection';
import HomeDescSection from '../sections/HomeDescSection';
import Footer from '../components/Footer';
import "./Pages.css";
import Contact from './Contact';
import clientsLogos4 from "../assets/clients-logos-1.4.png";

export default function Home(){
 
return (
    <div className="page-container">
        <HeroSection/>
        <HomeDescSection/>
        <section className="clients-section" id="clients-section">
            <h2>Companies I've worked with</h2>
            <h3>at Talentic GmbH and as a freelancer</h3>
            <img className="clients-logos" src={clientsLogos4} alt="Logos of the clients I've worked with" />
        </section>
        <Footer/>
    </div>
)

}