import React from 'react'
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./GraphicDesignProjects.css";
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import TalenticLogo from "../../../assets/talentic-logo.png";
import TalenticServiceCatalogue from "../../../assets/project-assets/talentic-assets/talentic-service-catalogue.png";

export default function GraphicDesignProject1() {
  return (
    <div className='graphic-design-project' id="graphic-design-project-1">
    <h1>Graphic Design Project</h1>
      <ProjectOverviewSection
        logo={TalenticLogo}
        desc="Talentic GmbH is a specialized personnel service for recruiting on demand, HR management and talent marketing from Munich, Germany. This project included designing an all-encompassing digital service catalogue to showcase everything Talentic has to offer."
        preview=""/>

        <section id="graphic-design">
          <div className="minimal-project-section-container">
            <img src={TalenticServiceCatalogue} className="project-image" alt="Talentic service catalogue" />
          </div>
        </section>
        <CTASection heading="Interested in working together?" text="Contact me" url="/contact"/>
        <Footer/>
    </div>
    )
}
