import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Impressum from './pages/Impressum';
import { Route, Routes } from 'react-router-dom';
import WebDevelopment from './pages/web development/WebDevelopment';
import UIUXDesign from './pages/ui ux design/UIUXDesign';
import GraphicDesign from './pages/graphic design/GraphicDesign';
import BrandIdentity from './pages/brand identity/BrandIdentity';
import UIUXDesignProject1 from './pages/ui ux design/projects/UIUXDesignProject1';
import UIUXDesignProject2 from './pages/ui ux design/projects/UIUXDesignProject2';
import UIUXDesignProject3 from './pages/ui ux design/projects/UIUXDesignProject3';
import WebDevelopmentProject1 from './pages/web development/projects/WebDevelopmentProject1';
import WebDevelopmentProject2 from './pages/web development/projects/WebDevelopmentProject2';
import WebDevelopmentProject3 from './pages/web development/projects/WebDevelopmentProject3';
import GraphicDesignProject1 from './pages/graphic design/projects/GraphicDesignProject1';
import GraphicDesignProject2 from './pages/graphic design/projects/GraphicDesignProject2';
import GraphicDesignProject3 from './pages/graphic design/projects/GraphicDesignProject3';

function App() {

  return (
    <div className="App">
      <Navbar/>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="contact" element={<Contact/>} />
          <Route path="about" element={<About/>} />
          <Route path="impressum" element={<Impressum/>} />
          <Route path="ui-ux-design" element={<UIUXDesign/>}/>
          <Route path="web-development" element={<WebDevelopment/>}/>
          <Route path="graphic-design" element={<GraphicDesign/>}/>
          <Route path="brand-identity" element={<BrandIdentity/>}/>

          <Route path="/ui-ux-design/project-shiga-ui-ux" element={<UIUXDesignProject1/>} />
          <Route path="/ui-ux-design/project-talentic-ui-ux" element={<UIUXDesignProject2/>} />
          <Route path="/ui-ux-design/project-tinkersforge-ui-ux" element={<UIUXDesignProject3/>} />

          <Route path="/web-development/project-teba-website" element={<WebDevelopmentProject1/>} />
          <Route path="/web-development/project-talentic-website" element={<WebDevelopmentProject2/>} />
          <Route path="/web-development/project-sport1-website" element={<WebDevelopmentProject3/>} />

          <Route path="/graphic-design/project-talentic-social-media" element={<GraphicDesignProject1/>} />
          <Route path="/graphic-design/project-dogtoday-print-media" element={<GraphicDesignProject2/>} />
          <Route path="/graphic-design/project-talentic-service-catalog" element={<GraphicDesignProject3/>} />
        </Routes>  
      </div> 
    </div>
  );
}

export default App;
