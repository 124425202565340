import React from 'react'
import "./WebDevelopmentServiceOverview.css";
import OpenCard from '../../components/OpenCard';
import CheckmarkIcon from '../../assets/icons/checkmark-icon.png';
import UserExperienceIcon from '../../assets/icons/user-experience-icon.png';
import WireframingPrototypingIcon from '../../assets/icons/wireframing-prototyping-icon.png';
import WebDesignIcon from '../../assets/icons/web-design-icon.png';
import WebDevelopmentIcon from '../../assets/icons/web-development-icon-2.png';
import WebDevelopmentIconGradient from '../../assets/icons/web-development-icon-gradient.png';

export default function WebDevelopmentServiceOverview() {
  return (
    <div className='service-overview-container'>
      <div className='service-overview-title'>
        <img src={WebDevelopmentIconGradient} alt="Checkmark Icon" className="service-overview-icon" />
        <h1>My approach to web development</h1>
      </div>
      <p className='service-overview-text'>My approach to web design and development is based on meticuliusly adhering to principles modern UI/UX design and utilizing user-centered design methods to ensure a delightful user experience. This usability-first approach ensures that the resulting website will be not only accessible, but also aesthetically beautiful and satisfying to use. </p>
      <div className='open-cards-container'>
        <OpenCard name="User experience" img={UserExperienceIcon} items={["User research & analysis", "Information architecture", "Usability & accessibility", "Testing & evaluation"]}/>
        <OpenCard name="Prototyping" img={WireframingPrototypingIcon} items={["Low-fidelity wireframes", "Interactive prototypes", "Feedback & testing"]}/>
        <OpenCard name="Web design" img={WebDesignIcon} items={["Custom layout & architecture", "Modern user interface design", "Authentic visual design", "Responsive/adaptive design"]}/>
        <OpenCard name="Web development" img={WebDevelopmentIcon} items={["Webflow Development", "E-Commerce", "Content management system"]}/>
      </div>
    </div>
  )
}
