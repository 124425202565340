import React from 'react'
import ProjectOverviewSection from '../../projects/ProjectOverviewSection';
import "./WebDevelopmentProjects.css";
import Sport1Logo from "../../../assets/sport1-logo.png";
import ProjectSport1WebsitePreview from "../../../assets/project-previews/project-sport1-website-preview.png"
import ProjectOutline from '../../../components/ProjectOutline';
import CTASection from '../../../components/CTASection';
import Footer from '../../../components/Footer';

import Sport1Persona1 from "../../../assets/project-assets/sport1-assets/sport1-persona-1.png";
import Sport1Persona2 from "../../../assets/project-assets/sport1-assets/sport1-persona-2.png";
import Sport1InformationArchitecture from "../../../assets/project-assets/sport1-assets/sport1-information-architecture.png";
import Sport1WireframingPrototyping from "../../../assets/project-assets/sport1-assets/sport1-wireframing-prototyping.png";
import Sport1MockupMobile from "../../../assets/project-assets/sport1-assets/sport1-mockup-mobile.png";
import Sport1Copywriting from "../../../assets/project-assets/sport1-assets/sport1-copywriting.png";
import Sport1DesignGuide from "../../../assets/project-assets/sport1-assets/sport1-design-guide.svg";
import Sport1Prototype1 from "../../../assets/project-assets/sport1-assets/sport1-prototype-1.png";
import Sport1Prototype2 from "../../../assets/project-assets/sport1-assets/sport1-prototype-2.png";

import AccessibilityIconColor from "../../../assets/icons/accessibility-icon-color.png";
import WebflowImage from "../../../assets/project-assets/sport1-assets/webflow-image.png";

export default function WebDevelopmentProject3() {
  return (
    <div className='web-development-project'>
    <h1>Web Development Project</h1>
      <ProjectOverviewSection
        logo={Sport1Logo}
        desc="Sport1 is a German free-to-air television channel centred towards sports programming. Their accounting section was seeking candidates for 2 open positions. Our project revolved around making an appealing landing page that communicates the benefits & opportunities they offer to candidates, while complying with Sport1's existing corporate identity."
        preview={ProjectSport1WebsitePreview}/>
      <ProjectOutline  color="#FAB500" title="Case study: Sport1 landing page" numbers ={["01", "02", "03", "04"]} chapters={["User experience", "User Interface Design", "Mobile responsiveness", "Webflow Development"]}/>
    
      <section id="user-experience">
        <div className="project-section-container">
          <h2><span className="sport1-color">01</span> User Experience</h2>
          <div className="project-segment">
            <h3>Briefing, research & analysis</h3>
            <p>The first phase of developing the landing page was to analyse the concrete goals and aims of the project through a short briefing session. With this information, I could derive a content strategy for their recruitment landing page, which should include information about two open positions.</p>
          </div>
          <div className="project-segment">
            <h3>User personas</h3>
            <p>In order to find the appropriate visual language for potential accounting and bookkeeping candidates and offering them an enjoyable UX, the first step was to define user personas.</p>
            <img src={Sport1Persona1} className="project-image" alt="Sport1 user persona 1" />
            <img src={Sport1Persona2} className="project-image" alt="Sport1 user persona 2" />
          </div>
          <div className="project-segment">
            <h3>Information architecture</h3>
            <p>The solution to Sport1's goal of finding suitable candidates could be found in a simple recruitment landing page, which is perfectly suitable for conveying their intent. The following micro-information architecture helps communicate all relevant information in a concise manner while keeping the page easily navigable for all users.</p>
            <img src={Sport1InformationArchitecture} className="project-image" alt="Sport1 information architecture" />
          </div>
          <div className="project-segment">
            <h3>Wireframing & prototyping</h3>
            <p>The wireframing process includes developing simple, low-fidelity sketches of the overall page layout and information structure - in constant communication with the client. This ensures full understanding and precision about the general idea, goals & desires before moving on to more resource-intensive stages of development.</p>
            <p>Prototyping takes this agreed upon base to the next level, by designing an interactive click-dummy for the client to test. Only after the wireframes and prototypes have been meticulously tested, evaluated and optimized does the final design go into development. This prevents unnecessary wasting of time and resources in later stages where changes are harder to make.</p>
            <img src={Sport1WireframingPrototyping} className="project-image" alt="Sport1 wireframing and prototyping process" />
          </div>
          <div className="project-segment">
            <h3>UX copyriting</h3>
            <p>To communicate the appropriate mood, values and information to potential candidates while offering a readable, enjoyable and painless user experience,  Sport1's recruitment landing page needed carefully curated textual content.</p>
            <img src={Sport1Copywriting} className="project-image" alt="Sport1 copywriting" />
          </div>
          <div className="project-segment">
            <h3>Usability & Accessibility</h3>
            <div className="segment-columns">
                <p>To make Sport1's landing page an enjoyable user experience that is accessible to all users regardless of visual and motor disabilities, I have laid great importance in adhering to official accessibility & usability standards. Most notably, Google Material Design guidelines,W3C Web Content Accessibility Guidelines (WCAG) & various Usability Heuristics ensure that the website remains robust, perceivable, operable and understandable to all.</p>
                <img className="segment-columns-icon" src={AccessibilityIconColor} alt="Accessibility icon" />
            </div>
          </div>
            <CTASection className="CTASection" heading="Interested in seeing more?" text="UI/UX Design" url="/ui-ux-design"/>
        </div>
      </section>

      <section id="user-interface-design">
        <div className="project-section-container">
          <h2><span className="sport1-color">02</span> User interface design</h2>
          <div className="project-segment">
            <h3>Design guide</h3>
            <p>To ensure consistent development and maintenance of the digital user interface, you will receive a comprehensive design guide for the website/app. This includes things like the color palette, typography stylings, icon styles, basic UI building blocks etc.</p>
            <img src={Sport1DesignGuide} className="project-image" alt="Tea Elektrotechnik design guide" />
          </div>
          <div className="project-segment">
            <h3>High-fidelity UI prototype</h3>
            <p>Here's a few screenshots of the final UI prototype that resulted from all the previous steps and considerations. This is the 1:1 design of the website that then goes into development.</p>
            <img src={Sport1Prototype1} className="project-image" alt="Sport1 high-fidelity UI prototype 1" />
            <img src={Sport1Prototype2} className="project-image" alt="Sport1 high-fidelity UI prototype 2" />
          </div>
        </div>
      </section>

      <section id="mobile-responsiveness">
        <div className="project-section-container">
          <h2><span className="sport1-color">03</span> Mobile responsiveness</h2>
          <div className="project-segment">
            <p>To ensure the website's responsiveness across all systems & devices, I've dedicated special attention to designing the mobile version of the website.</p>
            <img src={Sport1MockupMobile} className="project-image" alt="Sport1 website mobile mockup" />
          </div>
        </div>
      </section>

      <section id="webflow-development">
        <div className="project-section-container">
          <h2><span className="sport1-color">04</span> Webflow Development</h2>
          <div className="project-segment">
            <p>The final design was ultimately developed into a responsive website using Webflow. This step also included extra support services such as ongoing site maintenance and basic Search Engine Optimization (SEO).</p>
            <img src={WebflowImage} className="project-image" alt="Webflow logo image" />
          </div>
        </div>
      </section>


      <CTASection heading="Interested in working together?" text="Contact me" url="/contact"/>
      <Footer/>
    </div>
  )
}
