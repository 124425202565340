import React from 'react'
import Footer from "../components/Footer";
import "./Pages.css";

export default function Impressum() {
  return (
    <div className='page-container'>
        <section className="impressum-section">
            <h1>Impressum</h1>
            <div className="impressum-info">
                <p><strong className='bold-text-color'>Company:</strong> Luka Zec Mediendesign</p>
                <p><strong className='bold-text-color'>Adress:</strong> Stöberlstraße 50 80686 München</p>
                <p><strong className='bold-text-color'>Phone:</strong> +49 177 691 4820</p>
                <p><strong className='bold-text-color'>E-Mail:</strong> zecluka70@gmail.com</p>
            </div>
        </section>
        <Footer/>
    </div>
  )
}
