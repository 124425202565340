import Footer from "../components/Footer";
import ContactSection from "../sections/ContactSection";
import ContactInfo from "../components/ContactInfo";


export default function Contact(){
    return(
        <div className="page-container">
            <ContactSection/>
            <Footer/>
        </div>
    )

 }